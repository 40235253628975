// import styled from 'styled-components';
import FAQ from "./Components/Faq";
import Navbar from "./Components/Navbar";
import Venue from "./Components/Venue";
import Hero from "./Components/hero";
import Days from "./Components/days";
import Footer from "./Components/footer";
import Photo from "./Components/photo";
import CarouselDiv from "./Components/Carousel";
import ContactUs from "./Components/ContactUs";
import Supporter from "./Components/supporters";
import Mentor from "./Components/mentor";
import Prizes from "./Components/prize";
function App() {
  return (
    <>
      <Navbar></Navbar>
      <Hero></Hero>
      <Venue></Venue>
      <Days></Days>
      <Prizes></Prizes>
      {/* <Mentor></Mentor> */}
      <CarouselDiv></CarouselDiv>
      <Supporter></Supporter>
      {/* <FAQ></FAQ> */}
      <ContactUs />
      <Footer></Footer>
    </>
  );
}

export default App;
