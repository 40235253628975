import React from "react";
import styled from "styled-components";
import logo from "../Assets/logo.png";
import facebook from "../Assets/facebook.png";
import instagram from "../Assets/instagram.png";
import linkedin from "../Assets/linkedin.png";

const Container = styled.div`
  background-color: #fbc3bd;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  padding: 10px 0px;
  /* box-shadow: 0px 4.126803398132324px 51.58504104614258px 0px #00000033; */
  justify-content: space-evenly;
    width: 100%;

  @media (max-width: 1024px) {
    
  }

  @media (max-width: 650px) {
    flex-direction: column;
  }

  @media (max-width: 376px) {
  }
`;

const Logo = styled.img`
  filter: invert(1);
  height: 86px;
  padding-top: 20px;

  @media (max-width: 1024px) {
    
  }

  @media (max-width: 768px) {
    padding : 0px;
    height: 73px;
  }

  @media (max-width: 376px) {
    width: 40%;
    height:auto;
    padding:0;
  }
`;
const Title = styled.p`
  font-size: 21px;
  font-family: "Rajdhani", sans-serif;
  margin: 26px;
  @media (max-width: 1024px) {
    font-size: 18px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 376px) {
    font-size: 15px;
  }
`;
const Icon = styled.img`
  /* height: 30px;
  margin: 10px; */
  height: 36px;
    opacity: 52%;
    margin: 13px;

  @media (max-width: 1024px) {
    
  }

  @media (max-width: 768px) {
    
  }

  @media (max-width: 376px) {
    height:15px;
    margin: 7px;
  }  
`;
const Icons = styled.div``;

const Div = styled.div`
padding: 0px 11px
`
const Content = styled.div`
background-color: #fbc3bd;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0px;
  margin-top: 10%;
  box-shadow: 0px 4.126803398132324px 51.58504104614258px 0px #00000033;
`


const A = styled.a`
`

const Footer = () => {
  return (
    <Content id="footer">
    <Container>
      <Logo src={logo} />
      <Title>Developed with ❤ by Technical Team</Title>
      <Icons>  
        <A href="https://www.instagram.com/technicalteam_siesgst/" target="_blank">
        <Icon src={instagram} />
        </A>
        <A href="https://www.linkedin.com/company/technical-team-sies-gst/mycompany/" target="_blank">
        <Icon src={linkedin}/>
        </A>
      </Icons>
      </Container>
    </Content>
    
  );
};

export default Footer;
