import React from "react";
import styled from "styled-components";

import img from "../Assets/confetti.png";
import right from "../Assets/confetti-r.png";
import left from "../Assets/confetti-l.png";

const Container = styled.div`
border-radius: 12px;
background-color: #f5a0b0;
overflow: hidden;
/* margin : 0px 15%; */
margin: 5% 0;
/* width: 72%; */
width: 68%;
    /* height: 65vh; */
height: 56vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1190px) {
        height: 52vh;   
    }
    @media screen and (max-width: 970px) {
        height: 65vh   
    }
    @media screen and (max-width: 748px) {
        width: 80%;   
    }
    @media screen and (max-width: 550px){
        margin: 20% 0;
        height: 52vh;   
    }
    @media screen and (max-width: 390px){
        height: 45vh;   
    }
`
const LeftConfetti = styled.img`
    position: relative;
    opacity: 50%;
    height: 189px;
    /* left: 49px; */
    left: 13%;
    /* top: 53px; */
    top: 12%;
    mix-blend-mode: plus-lighter;
    @media screen and (max-width: 1300px){
        left: 16%;   
    }
    @media screen and (max-width: 970px) {
     left   :18%;
     top: 4%;
    }
    @media screen and (max-width: 800px) {
     left   :20%;
     top: -2%;
    }
    @media screen and (max-width: 748px) {
     left   :15%;
    }
    @media screen and (max-width:650px) {
     left  :17%;
    }
    @media screen and (max-width:570px) {
     left  :19%;
    }
    @media screen and (max-width: 490px) {
    left: 21%;
    height: 145px;
}
@media screen and (max-width: 390px){
    left: 105px;
    height: 112px 
}
@media screen and (max-width: 320px){
    left: 101px; 
}
    `

const RightConfetti = styled.img`
position: relative;
    opacity: 50%;
    height: 189px;
    mix-blend-mode: plus-lighter;
    /* right: 50px; */
    right: 13%;
    /* top: 58px; */
    top: 12%;
    @media screen and (max-width: 1300px){
        right: 16%;   
    }
    @media screen and (max-width: 970px) {
     right   :18%;
     top: 4%;
    }
    @media screen and (max-width:800px) {
     right   :20%;
     top: -2%;
    }
    @media screen and (max-width:780px) {
     right   :18%;
    }
    @media screen and (max-width:650px) {
     right   :17%;
    }
    @media screen and (max-width:570px) {
     right  :19%;
    }
    @media screen and (max-width: 490px) {
    right: 21%;
    height: 145px;
}
@media screen and (max-width: 390px){
    right: 105px;
    height: 112px 
}
@media screen and (max-width: 320px){
    right: 101px; 
}
    `

const Confetti = styled.img`
width: 100%;
    height: 167px;
    opacity: 50%;
    mix-blend-mode: plus-lighter;
    top: 20%;
    position: relative;

    @media screen and (max-width: 1440px) {
        top: 12%;  
    }
    @media screen and (max-width: 1210px) {
        top: 14%;   
    }
    @media screen and (max-width: 1050px){
        top: 8%;  
    }
    @media screen and (max-width: 970px){
        top: 7%;  
    }
    @media screen and (max-width: 850px){
        top: 2%;   
    }
    `

const ConfettiDiv = styled.div`
display: flex;
/* @media screen and (max-width: 420px){
    width: 169%;   
}
@media screen and (max-width: 340px){
    width: 182%;   
} */
`

const Content = styled.div`
display : flex;
justify-content: center;
/* width: 100%; */
width: 68vw;
padding : 0px 50px;
margin-top: 0px;
position: relative;
bottom: 15px;
@media screen and (max-width: 970px) {
 flex-direction   : column ;
}
`
const Div = styled.div`
margin: 20px;
text-align: center;
padding : 10px;
@media screen and (max-width: 970px){
 margin   : 0px ;
}`

const Title = styled.p`
font-weight: 600;
font-size:50px;
margin-bottom: 10px;
@media screen and (max-width: 1050px) {
    font-size: 42px;   
}
@media screen and (max-width: 970px){
    margin-top: 0px;   
}
@media screen and (max-width: 840px){
    font-size: 31px;   
}

@media screen and (max-width: 550px)
 {
    font-size: 28px;
}
@media screen and (max-width: 390px) {
    font-size: 25px;
}
@media screen and (max-width: 320px) {
    font-size: 21px;
}
`
const Money = styled.p`
font-weight: 500;
font-size: 80px;
color: red;
margin-top: 0px;
@media screen and (max-width: 1050px) {
    font-size: 69px;   
}
@media screen and (max-width: 970px){
 margin-bottom   : 0px ;
}
@media screen and (max-width: 840px){
    font-size: 60px;   
}
@media screen and (max-width: 550px)
{
    font-size: 48px;
}
@media screen and (max-width: 480px){
    font-size: 49px;   
}
@media screen and (max-width: 390px) {
    font-size: 41px;
}
@media screen and (max-width: 320px) {
    font-size: 33px;
}
`

const Main = styled.div`
display: flex;
justify-content: center;
`;

const Prize = () => {
    return(
        <Main>
        <Container>

    <Confetti src={img} />
<ConfettiDiv>
    <LeftConfetti src={left} />
<Content>
    <Div>
        <Title>Cash prize of
        </Title>
        <Money>
        ₹ 6,00,000
        </Money>
    </Div>
    <Div>
        <Title>
Crypto Worth
        </Title>
        <Money>
$3000
        </Money>
    </Div>
</Content>
    <RightConfetti src={right} />
</ConfettiDiv>
        </Container>
        </Main>
    )
}
export default Prize;
