import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  @media screen and (min-width: 320px) and (max-width: 750px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 83%;
    text-align: center !important;
  }
  @media screen and (min-width: 750px) and (max-width: 750px) {
    flex-direction: row;
    justify-content: center;
    width: 65%;
  }
  @media screen and (min-width: 1024px){
    justify-content: space-around;
    width: 71%; 
  }
  @media screen and (min-width: 1200px) {
    width: 62%; 
  }
  @media screen and (min-width: 1300px) {
    width: 66%;
}

`;

const Div = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin: 5% 0px;
  scroll-margin-top: 80px;
`;

const Right = styled.div`
  margin: 0px 50px;
  text-align: center;

  @media screen and (min-width: 320px) and (max-width: 750px) {
    margin-bottom: 5%;
  }
  @media screen and (min-width: 750px) {
    text-align: start;
    margin: 0px 10px;
  }
  @media screen and (max-width: 1024px) and (min-width: 950px) {
    margin-left: 40px;
  }
  @media screen and (min-width: 1024px) {
    margin-left: 56px;
  }

  
`;

const Left = styled.div`
  margin: 0px 50px;
  text-align: center;
  @media screen and (min-width: 320px) and (max-width: 750px) {
    margin-bottom: 5%;
  }
  @media screen and (min-width: 750px) {
    margin: 0px;
  }
  @media screen and (max-width: 1024px) and (min-width: 950px) {
    margin-left: 15px;
  }
  @media screen and (min-width: 1024px) {
    margin-right: 46px;
  }

 
`;

const Day = styled.p`
  font-size: 30px;
  color: #e5193d;
  font-weight: 600;
  margin: 0px;
  position: relative;
  /* bottom: 35px;
  left: 70px; */
  bottom: 23px;
    left: 96px;
  font-family: "Rajdhani", sans-serif;
  width: max-content;

  @media screen and (max-width: 480px) {
    bottom: 14px;
    left: 135px; 
    font-size: 25px;
  }
  @media screen and (max-width : 650px){
    bottom: 13px;
    left: 157px; 
  }
  @media screen and (max-width: 750px){
    left: 223px; 
    bottom: 15px;
  }
  @media screen and (max-width: 503px) {
        /* left: 149px; */
        left: 152px !important;
  } 
  @media screen and (max-width: 390px)
 {
    left: 121px!important;
}
@media screen and (max-width: 330px)
 {
    left: 95px!important;
}
  
`;

const Description = styled.p`
  font-weight: 400;
  font-family: "Titillium Web", sans-serif;
  line-height: 34px;
  margin-top: 0px;
  font-size: 18px;
  margin-bottom: 31px;
  text-align: start;
  @media screen and (min-width: 320px) and (max-width: 750px) {
    line-height: 27px;
    font-size: 15px;
  }
  @media screen and (min-width: 1300px) {
   font-size :20px ;
  }
  @media screen and (max-width :750px){
    text-align: center; 
  }

`;

const Bg = styled.p`
  font-family: "Rajdhani", sans-serif;
  font-size: 57px;
  font-weight: 600;
  color: white;
  margin-bottom: 0px;
  width: 73vw;
  @media screen and (min-width: 320px) and (max-width: 750px) {
    font-size: 40px;
  }
  @media screen and (min-width: 751px) {
    text-align: start;
    width: 41vw;

  }
  @media screen and (min-width: 1024px) {
    font-size: 63px; 
  }
  @media screen and (min-width :1000px){
    width: 332px  
  }

`;

const Top = styled.div`

`;

const A = styled.a`
color: #e5193d;
`

const Note = styled.div`
  font-weight: 400;
  font-family: "Titillium Web", sans-serif;
  width: 32%;
  border: 1px solid #e5193d;
  padding: 20px 73px;
  margin: 20px;
  margin-top: 40px;
  text-align: center;
  font-size: 18px;

  @media screen and (min-width: 320px) and (max-width: 750px) {
    width: 61% !important;
  }

  @media screen and (min-width: 320px) and (max-width: 750px) {
    width: 69%;
    border: 1px solid #e5193d;
    padding: 20px 39px;
    text-align: center;
    line-height: 25px;
    font-size: 15px;
  }
  @media screen and (min-width: 650px) and (max-width: 750px) {
    width: 65%;
  }
  @media screen and (max-width: 950px) {
    width: 64%;
  }
  @media screen and (min-width: 950px) and (max-width: 1024px) {
    width: 62%;
  }
  @media screen and (min-width: 1024px) {
    width: 59%;
  }
  @media screen and (min-width: 1200px){
    width: 52%;
}

@media screen and (min-width: 520px) {
  width: 67%; 
}  
@media screen and (max-width: 840px) {
  width: 47%; 
}
@media screen and (min-width: 900px){
  width: 53%; 
}
@media screen and (min-width: 1300px) {
   font-size :20px ;
   width: 57% ;
  }
`;

const Btn = styled.a`
  font-family: "Titillium Web", sans-serif;
  text-decoration: none;
  border: #e5193d;
  background-color: #e5193d;
  padding: 10px;
  margin: 15% auto;
  color: white;
  width: 200px;
  font-size: 18px;
  margin-top: 30px ;
  border-radius: 5px;
`;

function Days() {
  return (
    <Div id="about">
      <Container>
        <Left>
          <Top>
            <Bg>DAY 01</Bg>
            <Day>Dev Summit</Day>
          </Top>
          <Description>
            Developer Summit is a series of
            interactive sessions where industry
            expert speakers share their ideas and
            experience in various technical fields,
            helping young minds get started with
            their own ideas in the future.
          </Description>
        </Left>
        <Right>
          <Top>
            <Bg>DAY 02 & 03</Bg>
            <Day>ByteCamp 2024</Day>
          </Top>
          <Description>
            ByteCamp presents a chance to transform
            innovative concepts into impactful
            realities and be at the forefront of
            technological evolution. It's a realm where
            ideas transcend boundaries and
            innovation knows no limits.
            For more details <A target="blank" href="https://bytecamp.siesgst.ac.in/">
              Visit ByteCamp Website
            </A>
          </Description>
          <Btn target="blank" href="https://unstop.com/hackathons/bytecamp24-sies-graduate-school-of-technology-navi-mumbai-maharashtra-841503">Apply with Unstop</Btn>

        </Right>
      </Container>

      {/* <Note>
        Join us and discover new ways to educate. Develop an idea that will
        spark innovation and transform Mumbai
      </Note> */}
    </Div>
  );
}

export default Days;
