import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
background-color: #ffbcbc;
margin: 3rem auto;
width: 80%;
box-shadow: 0px 4.12px 51.58px 0px #00000033;
padding: 2rem;
border-radius: 12px;
@media screen and (max-width: 768px) {
    align-items: normal;
}
@media screen and (max-width: 1140px) {
    flex-direction: column;
    /* width: 100%; */
}

`;
const Profile = styled.img`
height: 50vh;
width: 100%;
object-fit: cover;
@media screen and (max-width: 576px) {
    width: 100%;
    /* height: 40vh; */
    object-fit: contain;
}
@media screen and (max-width: 992px) {
    height: auto;
}
`;
const Description = styled.div`
padding: 1rem;
line-height: 1.5;
width: 80%;
color: black;
font-size: 20px ;
text-align: center;
@media screen and (max-width: 992px) {
        font-size: 18px;
    }
    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
    @media screen and (max-width: 576px) {
        font-size: 14px;
    }
`;
const Name = styled.h2`
color: red;
margin: auto;
margin-top: 1rem;
text-align: left;
font-weight: bold;
font-size: 32px;
color: black;
@media screen and (max-width: 992px) {
        font-size: 28px;
        /* margin: 0.5rem 0; */
    }
    @media screen and (max-width: 768px) {
        font-size: 24px;
    }
    @media screen and (max-width: 576px) {
        font-size: 20px;

    }
`;
const Content = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

`;

 const Card = ({profile, name, description})=>{
    return(
        <Wrapper>
        <Profile src={profile}/>
        <Content>
        <Name>{name}</Name>
        <Description>{description}</Description>
        </Content>
        </Wrapper>
    );
 }
export default Card;