import React from "react";
import styled from "styled-components";
import logo from "../Assets/logo.png";
import computer from "../Assets/computer.png";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12% 15% 10% 15%;
  gap: 5%;

  @media (max-width: 768px) {
    margin: 22% 10% 10% 10%;
    flex-direction: column;
  }

  @media (max-width: 376px) {
    margin: 35% 5% 15% 5%;
  }

`;

const Left = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: space-between;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Right = styled.div`
  width:50%;

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
  }
 
`;

const Logo = styled.img`
  filter: invert(1);
  margin-bottom: 5%;
  width: 70%;
  height: auto;
  
  @media (max-width: 768px) {
    width: 60%;
    margin: 0 auto 5% auto;
  }

  @media (max-width: 376px) {
    margin: 0 auto 2% auto;
  }

`;

const HeroContent = styled.p`
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  line-height: 26px;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 18px;
  }

  @media (max-width: 376px) {
    font-size: 16px;
  }

`;

const Computer = styled.img`
  width:100%;

  @media (max-width: 768px) {
    width: 70%;
    margin: 5% auto 0 auto;
  }

`;

const Btn = styled.button`
  font-family: "Titillium Web", sans-serif;
  border: #e5193d;
  background-color: #e5193d;
  padding: 10px;
  margin: 10px;
  color: white;
  width: 200px;
  font-size: 18px;
`;

const Subtitle = styled.p`
  margin-bottom: 0px;
  color: #e5193d;
  font-family: "Rajdhani", sans-serif;
  font-weight: 600;
  font-size: 27px;
  line-height: 31px;

  @media (max-width: 768px) {
      text-align: center;
      font-size: 35px;
  }

  @media (max-width: 376px) {
    font-size: 25px;
  }
`;

function Hero() {
  return (
    <Container >
      <Left>
        <Logo src={logo} alt="Logo" />
        <Subtitle>Mumbai's Technological Fest</Subtitle>
        <HeroContent>
          Technical Team of SIES GST in collaboration with Navayuvak Entrepreneurs proudly introduces a pioneering initiative in
          Mumbai and Maharashtra with the launch of
          our inaugural technology festival.
          Acknowledging the prevailing trend of
          startups, our vision is to establish Mumbai as a
          formidable hub for technological innovation
          and entrepreneurship.
        </HeroContent>
        {/* <Btn>Apply using Unstop</Btn> */}
      </Left>
      <Right>
        <Computer src={computer} alt="Computer" />
      </Right>
    </Container>
  );
}

export default Hero;
