import React from "react";
import styled from "styled-components";
import speaker1 from "../Assets/jatin.jpg";
import speaker2 from "../Assets/samyak.jpg";
import Card from "./card";
const Container = styled.div`
margin: 20px;
margin-bottom: 50px;
    `
// const Heading = styled.p`
// font-size: 34px;    
// color: red;
// position: relative;
// left: 10%;
// font-weight: bold;
// `
//     const Card = styled.div`
//     display: flex;
//     background-color: #ffbcbc;
//     width: 30%;
//     margin: 20px;
//     box-shadow: 0px 4.126803398132324px 51.58504104614258px 0px #00000033;
//     @media screen and (min-width: 350px )and (max-width : 450px){
//     flex-direction: column;
//     width: 95%;
// }`

//     const Cards = styled.div`
//     display: flex;
//     justify-content: center;
//     @media screen and (min-width: 350px )and (max-width : 450px){
//     flex-direction: column;
// }`

//     const Image = styled.img`
//     height: 300px;`

//     const Name = styled.p`
//     color: red;
//     font-weight: bold;
//     font-size: 20px;
//     `
//     const Description = styled.p`
//     padding: 10px;
//     line-height: 1.5;`

const Wrapper = styled.div`
margin: 7%;
padding: 1rem;
min-height: 100vh;
max-width: 2550px;
`;
const Heading = styled.h1`
    font-size: 36px;
    color: red;
    font-weight: bold;
    width: 80%;
    margin: 1rem 10%;
    @media screen and (max-width: 576px) {
        font-size: 28px;
    }
`;

const promoterArray = [
    {profile: speaker1,
     name: "Jatin Malhotra",
     Description: "Developer Ecosystem Lead & Strategic Partnerships at Jio"},
     {profile: speaker2,
     name: "Samyak Chakrabarty",
     Description: "Founder and Managing Director Workverse"
    }];
    const Mentor = () => {
        return(
<Container>
<Wrapper>
    <Heading >Speakers & Mentors</Heading>
        {promoterArray.map((promoter, index) => (
            
            <Card key={index}
            profile = {promoter.profile}
            name = {promoter.name}
            description = {
                promoter.Description
            }>
            
        </Card>
))}
    </Wrapper>
</Container>
        )
    }

    export default Mentor;