import React from 'react'
import styled from 'styled-components';
import Promoter from '../Assets/gaurav.jpg';
import CoreTeam from '../Assets/teamPhoto.jpg'
import Card from './card';

const Wrapper = styled.div`
margin: 5% 7%;
padding: 1rem;
min-height: 100vh;
max-width: 2550px;
@media (max-width: 768px) {
    margin-top: 15%;
}
`;

const Heading = styled.h1`
    font-size: 36px;
    color: red;
    font-weight: bold;
    width: 80%;
    margin: 12px 10%;
    @media screen and (max-width: 576px) {
        font-size: 28px;
    }
`;

const Supporters = () => {
   const promoterArray = [
   { head: "Promoters", profile: Promoter,
    name: "Gaurav Mishra",
    Description: "A passionate entrepreneur working towards the development of the startup ecosystem by helping fellow entrepreneurs and creating an impact by his talks. He has been Awarded the Karmaveer Chakra, Instituted by UN and iCongo for his Tremendous Passion and Work towards Entrepreneurship from the start of his Entrepreneurial Journey, 8 years back."},
    { head: "The Core Team", profile: CoreTeam,
    name: "Technical Team",
    Description: "Our motto is to progress from information to knowledge to wisdom by sharing technical information, implementing it to gain knowledge, and continually improving for wisdom, to inculcate a sense of technical knowledge into students, a team wherein students could come and make their ideas turn into a reality with the help of others. The team values sharing, experimenting, and a passion for technology."}];
  return (

    <Wrapper id='supporter'>
        {promoterArray.map((promoter, index) => (
        <>
     <Heading >{promoter.head}</Heading>
            
            <Card key={index}
            profile = {promoter.profile}
            name = {promoter.name}
            description = {
                promoter.Description
            }>
            
        </Card>
        </>
))}
    </Wrapper>
  )
}

export default Supporters;