import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../Assets/logo.png';
import MenuImage from '../Assets/menu.svg';

const NavbarWrapper = styled.nav`
  background-color: #fbc3bd;
  cursor: pointer;
  padding: 10px;
  width: 100vw;
  height: 75px;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  box-shadow: 0px 4.126803398132324px 51.58504104614258px 0px #00000033;

  @media (max-width: 1024px) {
    height: 70px;
  }

  @media (max-width: 768px) {
    height: 65px;
  }
  
  @media (max-width: 376px) {
    height: 60px;
  }
`;

const NavLogo = styled.div`
width:10%;
margin-left:10%;

@media (max-width: 768px) {
  width: 20%;
}

@media (max-width: 376px) {
  width:30%;
}
`;

const LogoIMG = styled.img`
width: 100%;
margin-left: 20px;
z-index: 2;
filter: invert(1);
`;

const Navlist = styled.div`
  display: flex;
  font-weight:500;
  margin-right:10%;
  
  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;
    background-color: #fbc3bd;
    width: 35%;
    height: 100vh;
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    box-shadow: 0px 4.126803398132324px 51.58504104614258px 0px #00000033;
  }

  @media (max-width: 376px) {
    width: 60vw;
}
`;

const NavbarLink = styled.a`
  text-decoration: none;
  margin-right: 10px;
  color: #000000;
  margin: 0 2em;
  &:hover {
    text-decoration: none;
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    border-bottom: 1px solid #000000;
    width: 100%;
    text-align: center;
  }

`;

const HamburgerImage = styled.img`
width: 30px;
height: 30px;
display:none;

@media (max-width: 768px) {
display: block;
border: none;
cursor: pointer;
position: relative;
right: 50px;
z-index: 2;
}
`;

const Navbar = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const navList = document.getElementById('navList');
      if(navList && !navList.contains(event.target) && isOpen) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleOutsideClick);

  }, [isOpen]);

  return (
    <>
      <NavbarWrapper>
        <NavLogo onClick={() => scrollToSection('about')}><LogoIMG src={Logo}/></NavLogo>
        <HamburgerImage id='navList' onClick={toggleMenu} src={MenuImage}/>
        <Navlist isOpen={isOpen}>
          <NavbarLink onClick={() => scrollToSection('location')}>Location</NavbarLink>
          <NavbarLink onClick={() => scrollToSection('about')}>About</NavbarLink>
          <NavbarLink onClick={() => scrollToSection('supporter')}>Promoters</NavbarLink>
          <NavbarLink onClick={() => scrollToSection('contact')}>Contact Us</NavbarLink>
        </Navlist>
      </NavbarWrapper>
    </>
  );
};

export default Navbar;