import { useState } from 'react'
import styled from 'styled-components'
import DownArrow from '../Assets/downArrow.svg'

const MainWrapper = styled.div`
    margin: 7% 16%;
    background-color: #FBC3BD;
    padding: 2em 0 4em 0;
    scroll-margin-top: 180px;
    @media screen and (max-width: 1000px){
    margin: 7% 15%;
    
}
    @media (max-width: 868px) {
        /* margin: 0 10%; */
        margin: 14% 16%;
    }
    @media (max-width: 750px) {
        margin: 10%;
    }


    @media (max-width: 376px) {
       margin: 0 5%;
    }
    @media (max-width: 376px) {
       margin: 0 5%;
    }
`;

const Heading = styled.div`
    color: #E5193D;
    text-align: center;
    font-size: 35px;
    font-weight: 600;
    padding: 0.1em;

    @media (max-width: 376px) {
        font-size: 30px;
    }
`;

const FaqSection = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 2% 10% 0 10%;
    
    @media (max-width: 768px) { 
        margin: 0 auto;   
    }
    @media (max-width: 376px) {
        margin: 2% 10% 0% 10%; 
        margin-left:auto;
        margin-right:auto;       
    }

`;

const FaqContainer = styled.div`
    border-bottom: 1px solid #BC938F;
    width: 80%
`;

const FaqTitle = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    cursor: pointer;
    margin: 1em 0;
    
    @media (max-width: 376px) {
        align-items: center;
    }
    @media (max-width: 768px) {
        align-items: center;
    }
`;

const Question = styled.div`
    font-size: 20px;
    font-weight: 500;
    color: #000000;

    @media (max-width: 376px) {
        font-size: 15px;
    }
`;

const Arrow = styled.img`
    width: 20px;
    color: #E5193D !important;
    transform: ${({ isActive }) => (isActive ? 'rotate(180deg)' : 'none')};
    transition: transform 0.4s ease;
`;

const Answer = styled.div`
    text-align: center;
    font-size: 15px;
    color: #343434;
    width: 85%;
    margin-bottom: 1em;
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    overflow: hidden;
    opacity: ${({ isActive }) => (isActive ? '1' : '0')};
    height: ${({ isActive }) => (isActive ? 'auto' : '0')};
    @media (max-width: 376px) {
        font-size: 14px
    }
`;

const EmailBtn = styled.div`
    background-color: #ffffff;
    width: 40%;
    text-align: center;
    line-height: 3em;
    color: #E5193D;
    font-weight: 600;
    font-size: 1em;
    border-radius: 3px;
    margin: 5% auto 0 auto;

    @media (max-width: 1024px) {
        width: 45%;
    }

    @media (max-width: 768px) {
        width: 45vw;
    }

    @media (max-width: 376px) {
        width: 50vw;
        font-size: 0.8em;
        line-height: 1.5em;
        padding: 5px 0;
        margin: 10% auto 0 auto;
    }
`;

const EmailLink = styled.a`
    background-color: #ffffff;
    color: #E5193D;
    margin-left:1%
`;

function Faq() {

    const [clicked, setClicked] = useState(null)

    const toggle = (i) => {
        if (clicked==i){
            return setClicked(null);
        }
        setClicked(i)
    }

    const data = [
        {
            question: "It is a long established fact that a reader will be?",
            answer: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
        },
        {
            question: "It is a long established fact that a reader will be?",
            answer: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
        },
        {
            question: "It is a long established fact that a reader will be?",
            answer: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
        },
        {
            question: "It is a long established fact that a reader will be?",
            answer: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
        },
    ]

  return (
    <MainWrapper id='faq'>
      <Heading>FAQ</Heading>
      <FaqSection>
        {data.map((item, i) => (
            <FaqContainer key={i}>
                <FaqTitle onClick={() => toggle(i)}>
                    <Question>{item.question}</Question>
                    <Arrow src={DownArrow} isActive={clicked === i} />
                </FaqTitle>
                <Answer isActive={clicked === i}>{item.answer}</Answer>
            </FaqContainer>
        ))}
      </FaqSection>
      {/* <EmailBtn>ANY OTHER QUESTIONS, WRITE
            <EmailLink href='#'>@SIESGST.EMAIL</EmailLink>
      </EmailBtn> */}
    </MainWrapper>
  )
}

export default Faq