import React from "react";
import styled from "styled-components";
import Carousel from "react-elastic-carousel";
import Carousel1 from "../Assets/DS-1.jpg";
import Carousel2 from "../Assets/DS-2.jpg";
import Carousel3 from "../Assets/DS-3.jpg";
import Carousel4 from "../Assets/ByteCamp-1.jpg";
import Carousel5 from "../Assets/ByteCamp-2.jpg";
import Carousel6 from "../Assets/ByteCamp-3.jpg";

const Slider = styled.div`
  margin: 10% 5px 0 5px;
  .rec-arrow, .rec-dot {
    display: none;
  }
  .rec-pagination {
  }
  /* .rec-dot {
    width: 6px;
    height: 6px;
    background-color: #fbc3bd;
    border: 0;
    box-shadow: unset;
    margin: 3px;
  }
  .rec-dot_active {
    background-color: #e5193d;
  } */
  .rec-slider-container {
    border-radius: 20px;
  }
  @media screen and (min-width: 992px) {
    margin: 5% 10px 0 10px;
  }
`;

const Image = styled.img`
  border-radius: 12px;
  /* height: 242px; */
  height: 300px;
  object-fit: cover;
  width: 100%;
  /* mask-image: linear-gradient(rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 99%); */
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 10.94%, #000000 100%);
  @media screen and (min-width: 992px) {
    height: 400px;
  }
`;

const ImageContainer = styled.div`
  width: 88%;

  @media screen and (min-width: 768px) {
    width: 70%;
  }
`;

const ImageContent = styled.p`
  position: absolute;
  color: white;
  bottom: 10px;
  padding-left: 20px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #DCF2FF;
  @media screen and (min-width: 992px) {
    font-size: 26px;
    bottom: 2px;
    padding-left: 25px;
  }
  @media screen and (min-width: 576px) {
    font-size: 18px;
  }
`;

const NoteDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68%;
  margin: auto;

  /* font-weight: 400;
  font-family: "Titillium Web", sans-serif;
  width: 32%;
  border: 1px solid #e5193d;
  padding: 20px 73px;
  margin: 20px auto;
  margin-top: 40px;
  text-align: center;
  font-size: 18px;

  @media screen and (min-width: 768px) {
    width: 49%;
  }

  @media screen and (min-width: 992px) {
    width: 57%;
  } */

  /* @media screen and (min-width: 320px) and (max-width: 750px) {
    width: 61% !important;
  }

  @media screen and (min-width: 320px) and (max-width: 750px) {
    width: 69%;
    border: 1px solid #e5193d;
    padding: 20px 39px;
    text-align: center;
    line-height: 25px;
    font-size: 15px;
  }
  @media screen and (min-width: 650px) and (max-width: 750px) {
    width: 65%;
  }
  @media screen and (max-width: 950px) {
    width: 64%;
  }
  @media screen and (min-width: 950px) and (max-width: 1024px) {
    width: 62%;
  }
  @media screen and (min-width: 1024px) {
    width: 59%;
  }
  @media screen and (min-width: 1200px){
    width: 52%;
}

@media screen and (min-width: 520px) {
  width: 67%; 
}  
@media screen and (max-width: 840px) {
  width: 47%; 
}
@media screen and (min-width: 900px){
  width: 53%; 
}
@media screen and (min-width: 1300px) {
   font-size :20px ;
   width: 57% ;
  } */
`;

const Note = styled.p`
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  font-family: "Titillium Web", sans-serif;
  border: 1px solid #e5193d;
  border-radius: 5px;
  padding: 50px;
  @media (max-width: 768px) {
    padding: 25px;
    font-size: 14px;
  }
`;

const CarouselDiv = () => {
  const carouselRef = React.useRef();
    let resetTimeout;

    return (
        <>
<Slider>
          <Carousel
            ref={carouselRef}
            enableAutoPlay={true}
            enableSwipe={true}
            enableMouseSwipe={true}
            preventDefaultTouchmoveEvent={true}
            onNextEnd={({ index }) => {
              if (index + 1 === 6) {
                resetTimeout = setTimeout(() => {
                  if (carouselRef.current) carouselRef.current.goTo(0);
                }, 1500);
              }
            }}
          >
            <ImageContainer>
              <Image src={Carousel1} alt=""></Image>
              <ImageContent>Glimpses of Developers' Summit 2023</ImageContent>
            </ImageContainer>
            <ImageContainer>
              <Image src={Carousel2} alt=""></Image>
              <ImageContent>Glimpses of Developers' Summit 2023</ImageContent>
            </ImageContainer>
            <ImageContainer>
              <Image src={Carousel3} alt=""></Image>
              <ImageContent>Glimpses of Developers' Summit 2023</ImageContent>
            </ImageContainer>
            <ImageContainer>
              <Image src={Carousel4} alt=""></Image>
              <ImageContent>Glimpses of ByteCamp 2023</ImageContent>
            </ImageContainer>
            <ImageContainer>
              <Image src={Carousel5} alt=""></Image>
              <ImageContent>Glimpses of ByteCamp 2023</ImageContent>
            </ImageContainer>
            <ImageContainer>
              <Image src={Carousel6} alt=""></Image>
              <ImageContent>Glimpses of ByteCamp 2023</ImageContent>
            </ImageContainer>
          </Carousel>
        </Slider>

        <NoteDiv>
          <Note>
            Join us and discover new ways to educate. Develop an idea that will
            spark innovation and transform Mumbai
          </Note>
        </NoteDiv>
        </>
    )
};

export default CarouselDiv;
