import React, { useState } from "react";
import styled from "styled-components";
import emailjs from "@emailjs/browser";

const Heading = styled.div`
  color: #e5193d;
  text-align: center;
  font-size: 35px;
  font-weight: 600;
  padding: 0.1em;

  @media (max-width: 376px) {
    font-size: 30px;
  }
`;

const MainWrapper = styled.div`
  width: 73%;
  margin: 5% auto;
  background-color: #fbc3bd;
  padding: 2em 0;
  border-radius: 12px;
  scroll-margin-top: 180px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  @media screen and (min-width: 320px) and (max-width: 750px) {
    padding: 2em 0;
    width: 80%;
    margin-top: 15%;
  }
`;

const ContactDetails = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  padding: 0 24px;
  border-right: 1px solid #e5193d;

  @media screen and (min-width: 320px) and (max-width: 992px) {
    border-right: none;
    border-bottom: 1px solid #e5193d;
    align-items: center;
  }
`;

const Query = styled.p`
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  @media (max-width: 425px) {
    font-size: 20px;
   }
   @media (max-width: 375px) {
    font-size: 16px;
   }
`;

const Email = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 22px;
  gap: 5px;
`;

const Call = styled.div`
  display: flex;
  justify-content: start;
  font-size: 22px;
  gap: 10px;
  @media (max-width: 425px) {
    font-size: 20px;
  }
`;

const Title = styled.p`
  font-weight: 600;
  @media (max-width: 425px) {
    font-size: 18px;
   }
   @media (max-width: 375px) {
    font-size: 14px;
   }
`;

const Value = styled.p`
  display: flex;
  flex-direction: column;
  a {
    text-decoration: none;
    color: #000;
  }
  @media (max-width: 425px) {
    font-size: 18px;
   }
   @media (max-width: 375px) {
    font-size: 14px;
   }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 auto;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
  }
`;

const SecondaryWrapper = styled.div`
  display: flex;
  padding: 25px;

  @media screen and (min-width: 320px) and (max-width: 992px) {
    flex-direction: column;
  }
`;

const Form = styled.form`
  width: 60%;
  margin: 0 auto;
  padding: 10px;
  // border-left: 1px solid #e5193d;
  // @media screen and (min-width: 320px) and (max-width: 767px) {
  //   border-left: none;
  //   border-top: 1px solid #e5193d;
  // }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 80px;
  padding: 10px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Button = styled.button`
  font-family: "Titillium Web", sans-serif;
  background-color: #e5193d;
  color: white;
  padding: 10px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  &:disabled {
    opacity: 0.5;
  }
  &:enabled {
    opacity: 1;
  }
  opacity: ${(props) => (!props.enabled ? 0.5 : 1)};
`;

const Alert = styled.div`
  padding: 10px;
  background-color: #f44336;
  color: white;
  margin-top: 10px;
  border-radius: 5px;
`;

// const FlexBox = styled.div`
//   display: flex;
//   flex-direction: column;
// `;

export default function ContactUs() {
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    phone: "",
    query: "",
  });

  const [enabled, setEnabled] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  const handleChange = (e) => {
    setContactData({ ...contactData, [e.target.name]: e.target.value });
    console.log(contactData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(contactData, "hello");
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (contactData.name === "") {
      setEnabled(true);
      setAlertMsg("Name is required");
      return;
    } else if (
      contactData.email === "" ||
      !emailRegex.test(contactData.email)
    ) {
      setEnabled(true);
      setAlertMsg("Email is invalid");
      return;
    } else if (contactData.phone === "" || contactData.phone.length !== 10) {
      setEnabled(true);
      setAlertMsg("Phone number is invalid");
      return;
    } else if (contactData.query === "") {
      setEnabled(true);
      setAlertMsg("Query is required");
      return;
    } else {
      setEnabled(false);
      setAlertMsg("");
    }

    emailjs
      .sendForm("service_g6qaar3", "template_l7oazqo", e.target, {
        publicKey: "YLMLERURCX5-Yyl5R",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
      setContactData({
        name: "",
        email: "",
        phone: "",
        query: "",
      });
  };
  return (
    <MainWrapper id="contact">
      <Heading>Contact Us</Heading>
      <SecondaryWrapper>
        <ContactDetails>
          <Query>For any queries, please feel free to contact us.</Query>
          <Email>
            <Title>Email: </Title>
            <Value>
              <a href="mailto: council_tech@gst.sies.edu.in">
                {" "}
                council_tech@gst.sies.edu.in
              </a>
            </Value>
          </Email>
          <Call>
            <Title>Phone: </Title>
            <Value>
              <a href="tel: +91 9967700587"> +91 9967700587</a>
              <a href="tel: +91 8879110971"> +91 8879110971</a>
            </Value>
          </Call>
          {/* <b>For any queries, please feel free to contact us.</b>
          <p>
            <strong>Email:</strong>
            <a href="mailto: council_tech@gst.sies.edu.in">
              {" "}
              council_tech@gst.sies.edu.in
            </a>
          </p>
          <p>
            <strong>Phone:</strong>
            <a href="tel: +91 9967700587"> +91 9967700587</a>
            <br />
            <a href="tel: +91 8879110971"> +91 8879110971</a>
          </p> */}
        </ContactDetails>
        <Form onSubmit={handleSubmit}>
          <InputWrapper>
            <Input
              type="text"
              name="name"
              placeholder="Name"
              onChange={handleChange}
              value={contactData.name}
            />
            <Input
              type="number"
              placeholder="Phone Number"
              name="phone"
              onChange={handleChange}
              value={contactData.phone}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              type="email"
              placeholder="Email"
              name="email"
              onChange={handleChange}
              value={contactData.email}
            />
          </InputWrapper>
          <InputWrapper>
            <Textarea
              placeholder="Ask Query"
              name="query"
              onChange={handleChange}
              value={contactData.query}
            />
          </InputWrapper>
          <InputWrapper>
            <Button type="submit">Contact</Button>
          </InputWrapper>
          {enabled && <Alert>{alertMsg}</Alert>}
        </Form>
      </SecondaryWrapper>
    </MainWrapper>
  );
}
