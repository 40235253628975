import React from "react";
import styled from "styled-components";
import photo from "../Assets/63ffb0f21703e_whatsapp_image_2023-03-02_at_1.37 1.png"
import train from "../Assets/train.png"

const Container = styled.div`
    display: flex;
    margin: 4% 20%;
    margin-bottom: 114px;
    @media screen and (min-width: 350px ){
        width: 96%;
    display: flex;
    margin: 5%;
    margin-bottom: 94px;
    overflow-x: clip;
    }
`
const Image = styled.img`
width: 60vw;
@media screen and (min-width: 350px ){
    width: 89vw;   
    height: 276px;
}
@media screen and (min-width: 650px) {
    margin-left: 39px;   
}`

const Train = styled.img`
position: relative;
    top: 283px;
    right: 56%;
    height: 268px;
    @media screen and (min-width: 350px) {
        position: relative;
    top: 137px;
    right: 230px;
    height: 181px;  
    }
    @media screen and  (min-width: 500px){
        right: 278px;   
    }
    `
    const Div = styled.div`
    display: flex;
    justify-content: center;
    `

function Photo(){
    return(
        <Div>
<Container>
    <Image src={photo}/>
    <Train src={train}/>
</Container>
        </Div>
    )
}

export default Photo;
