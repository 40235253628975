import React from "react";
import styled from "styled-components";
import MapIMG from "../Assets/map.png";

const MainWrapper = styled.div`
  margin: 5% 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin: 0 10%;
  }
  @media (max-width: 376px) {
    margin: 0 5%;
  }
`;

const Map = styled.img`
  @media (max-width: 1024px) {
    width: 40%;
    height: 50%;
    margin-top: 5%;
  }

  @media (max-width: 768px) {
    width: 70%;
  }

  @media (max-width: 376px) {
    width: 80%;
  }
`;

const VenueCard = styled.div`
  text-align: center;
  margin: 10% 0 0 10%;

  @media (max-width: 1024px) {
    margin: 10% 0 0 5%;
  }

  @media (max-width: 768px) {
    width: 40vw;
    margin: 12% 5% 5% 5%;
  }

  @media (max-width: 376px) {
    margin: 15% 2%;
  }
`;

const VenueCardTitle = styled.div`
  font-size: 30px;
  color: #e5193d;
  font-weight: 600;

  @media (max-width: 1024px) {
    font-size: 25px;
  }

  @media (max-width: 768px) {
    text-align: center;
  }

  @media (max-width: 376px) {
    font-size: 26px;
    margin-top: 0px;
  }
`;

const VenueCardText = styled.div`
  font-size: 40px;
  font-weight: 800;

  @media (max-width: 1024px) {
    font-size: 35px;
  }

  @media (max-width: 768px) {
    text-align: center;
    font-size: 24px;
  }

  @media (max-width: 376px) {
    font-size: 20px;
  }
`;

const Div = styled.div`
  scroll-margin-top: 180px;
`;

const VenueContent = styled.div`
  display: flex;
  justify-content: space-around;
  
  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

function Venue() {
  return (
    <Div id="location">
      <MainWrapper>
        <Map src={MapIMG} />
        <VenueContent>
          <VenueCard>
            <VenueCardTitle>When?</VenueCardTitle>
            <VenueCardText>15th - 17th March 2024</VenueCardText>
          </VenueCard>
          <VenueCard>
            <VenueCardTitle>Where?</VenueCardTitle>
            <VenueCardText>
              SIES Graduate School Of Technology, Nerul, Navi Mumbai{" "}
            </VenueCardText>
          </VenueCard>
        </VenueContent>
      </MainWrapper>
    </Div>
  );
}

export default Venue;

